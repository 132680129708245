define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["fr-fr", {
    "atmosphere": {
      "community": {
        "description": "Entre aide et bon humeur à longueur de journée",
        "title": "Une communauté soudée"
      },
      "discord": {
        "description": "Pour animer tes soirées LoupGarou avec tes amis",
        "title": "Un Discord au poil"
      },
      "game": {
        "description": "Tous plus inédits les uns que les autres",
        "title": "Des mini-jeux à gogo"
      },
      "team": {
        "description": "Toujours là pour vous orienter",
        "title": "Une équipe au top"
      }
    },
    "contact": {
      "copy": "Email copié",
      "discord": "Sur discord",
      "email": "Par email",
      "title": "Contact"
    },
    "footer": {
      "cookies": "Mes cookies 🍪",
      "copyright": "2021-2024 Gaspézia Network",
      "legal_notice": "Mention légales",
      "players": "Joueurs",
      "social_media": "Réseaux :",
      "thanks": {
        "design": "Désigné par Julianne Mahot",
        "developer": "Développé par Julien Vannier"
      }
    },
    "mini_games": {
      "arena": {
        "description": "Le lieu où tous les gladiateurs peuvent régler leurs comptes ! Avec son PVP 1.8, tu peux défier tous tes amis et\ndevenir le meilleur joueur !\n",
        "subtitle": "Compétitif",
        "title": "Arena"
      },
      "bedwars": {
        "description": "La guerre des lits a commencé, seul ou en équipe, protège ton lit quoi qu’il en coûte !\n",
        "subtitle": "Compétitif",
        "title": "Bedwars"
      },
      "blocks-music": {
        "description": "Entre la piste de danse et le vide sous tes pieds, qui sera celui qui vaincra le DJ ?\n",
        "subtitle": "Arcade",
        "title": "<i class=\"fa-solid fa-person-digging\"></i> Blocs-Musicaux <i class=\"fa-solid fa-person-digging\"></i>"
      },
      "creative": {
        "description": "L’endroit parfait pour les constructeurs chevronnés ! Laisses ta créativité s’exprimer en toute facilité grâce à\nun accès WorldEdit et VoxelSniper. Laisse libre cours à ton imagination !\n",
        "subtitle": "Arcade",
        "title": "Créatif"
      },
      "lg": {
        "description": "Le LoupGarou est une réadaptation du jeu de société “LoupGarou de Thiercelieux”. Doté de nouveaux rôles pouvant\ninverser le sens de la partie, qui des villageois ou des loup-garous l’emportera ?\n",
        "subtitle": "Société",
        "title": "Loup Garou"
      },
      "more_info": "Plus d'information",
      "moutrun": {
        "description": "Inspiré des célèbres films Tron, notre réadaptation est quelque peu… inédite. Fonces à toute vitesse sur le dos\nde ton mouton afin d’éliminer tes ennemis et remporter la victoire.\n",
        "subtitle": "Arcade",
        "title": "Moutrun"
      },
      "survival": {
        "description": "Tu souhaites découvrir ou redécouvrir Minecraft ? Partager ton expérience ou faire des projets communs ? N’attends\nplus et rejoins les autres !\n",
        "subtitle": "Coopératif",
        "title": "Survie"
      },
      "title": "Mode de jeux"
    },
    "navigation": {
      "discord": "Discord",
      "dynmap": "Dynmap",
      "museum": "Musée",
      "vote": "Vote"
    },
    "news": {
      "known_more": "En voir +",
      "title": "Nouveautés"
    },
    "presentation": {
      "copy": "IP copiée",
      "description": "Gaspézia est un serveur francophone disposant de plusieurs modes de jeu tels qu'un survie, un créatif et pléthore de\nmini jeux comme le LoupGarou, l'Arena, le Moutrun et le Bedwars.\n",
      "server_url": "Gaspezia.fr",
      "title": "Bienvenue !",
      "version": {
        "maintenance": "Maintenance en cours, consultez nos réseaux sociaux",
        "success": "Version {version}"
      }
    }
  }]];
  _exports.default = _default;
});